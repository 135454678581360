<template>
	<div class="edit-form-container">
		<!-- :validate="" -->
		<el-form
			ref="itemForm"
			class="item-edit-form form-type-2"
			:model="formData"
			:rules="rules"
		>
			<div class="formRow mrow flex wrap">
				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Алиас фильтра" prop="alias">
						<el-input v-model="formData.alias" />
					</el-form-item>
				</div>
			</div>

			<div class="formRow mrow flex wrap">
				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Название фильтра (ru)" prop="title_ru">
						<el-input v-model="formData.title_ru" />
					</el-form-item>
				</div>

				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Назва фильтра (uk)" prop="title_uk">
						<el-input v-model="formData.title_uk" />
					</el-form-item>
				</div>
			</div>

			<!-- <div class="formRow mrow">
				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Категория товаров" prop="category_id">
						<SimpleSpinner :active="categoriesLoading" small />
						<el-select
							v-model="formData.category_id"
							placeholder="выберите категорию"
							:disabled="!categoriesList.length"
							@change="cleanFormDataProps(['attribute_group_id', 'attribute_id'])"
						>
							<el-option
								v-for="item in categoriesList"
								:key="'category_id-' + item.id"
								:label="item.title_ru"
								:value="item.id"
							/>
						</el-select>
					</el-form-item>
				</div>
			</div> -->

			<div class="formRow mrow flex wrap">
				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Группа атрибутов" prop="attribute_group_id">
						<SimpleSpinner :active="attributeGroupsLoading" small />
						<el-select
							v-model="formData.attribute_group_id"
							placeholder="выберите группу"
							:disabled="!attributeGroupsList.length"
							@change="cleanFormDataProps([
								{ accessor:'formData.attribute_id', val: null },
								{ accessor:'optionsList', val: [] }
							])"
						>
							<el-option
								v-for="item in attributeGroupsList"
								:key="'attribute_group_id-' + item.id"
								:label="item.title_ru"
								:value="item.id"
							/>
						</el-select>
					</el-form-item>
				</div>

				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Атрибут" prop="attribute_id">
						<SimpleSpinner :active="attributesLoading" small />
						<el-select
							v-model="formData.attribute_id"
							placeholder="выберите атрибут"
							:disabled="!attributesList.length || !formData.attribute_group_id"
							@change="cleanFormDataProps([
								{ accessor:'optionsList', val: [] }
							])"
						>
							<el-option
								v-for="item in attributesList"
								:key="'attribute_id-' + item.id"
								:label="item.title_ru"
								:value="item.id"
							/>
						</el-select>
					</el-form-item>
				</div>
			</div>

			<!-- ------------- -->
			<div class="formRow options-container">
				<div class="el-form-item__label">Варианты</div>
				<div class="with-options-select">
					<div
						v-if="optionsList.length && attributeOptionsList.length"
						class="content-row"
					>
						<OptionItem
							v-for="(item, idx) in optionsList"
							ref="OptionItem"
							:key="`option_item-${item.id}`"
							:item-data="item"
							:item-index="idx"
							:additional-options-list="attributeOptionsList"
							:form-data-prop="'filter_options'"
							@onRemove="id => removeFormItem(id, 'optionsList')"
							@ready="blockReady"
						/>
					</div>

					<div class="content-row option-item-container">
						<el-button
							class="action-button create-button"
							size="mini"
							type="success"
							icon="icomoon icon-cross"
							@click="addFormItem('optionsList', 'opt_i-')"
						/>
					</div>
				</div>
			</div>
		</el-form>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { required } from '@/constants/validation';
import {
	itemFormMixin,
	itemsFetchSetMixin,
	refsOperationsMixin,
	createFormItemMixin
} from '@/mixins';
import { updateFormData } from '@/helpers';

export default {
	components: {
		OptionItem: () => import('../../components/OptionItem.vue')
	},
	mixins: [
		itemFormMixin,
		itemsFetchSetMixin,
		refsOperationsMixin,
		createFormItemMixin
	],
	props: {
		itemData: {
			type: Object,
			default: () => ({})
		},
		fromAnotherInstance: Boolean
	},

	data() {
		return {
			itemId: null,
			optionsList: [],
			
			formData: {
				alias: '',
				title_uk: '',
				title_ru: '',
				// category_id: null,
				attribute_group_id: null,
				attribute_id: null
			}
		};
	},

	computed: {
		...mapState({
			attributesList: state => state.attributes.itemsList,
			attributesLoading: state => state.attributes.isLoading,
			attributeGroupsList: state => state.attribute_groups.itemsList,
			attributeGroupsLoading: state => state.attribute_groups.isLoading,
			attributeOptionsList: state => state.attributes.attributeOptionsList,
			attributeOptionsLoading: state => state.attributes.attributeOptionsLoading

			// categoriesList: state => state.categories.itemsList,
			// categoriesLoading: state => state.categories.isLoading
		}),

		refsList: () => ['OptionItem'],
		refsOperationsSettings: () => ({
			submitActionName: 'submitForm',
			dataAsArray: true,
			itemSubmitMethod: 'validateItemForm',
			// submitSettings: { formProp: 'filter_options' }
		}),

		rules: () => ({
			// alias: required,
			title_uk: required,
			title_ru: required,
			attribute_group_id: required,
			attribute_id: required
			// category_id: required,
		}),

		translitSettings: () => ({
			formFields: [{ prop: 'alias', sourceProp: 'title_ru', ifIsEmpty: true }]
		}),

		initialSetFetchSettings: () => [
			{
				action: 'fetch_attribute_groups',
				payload: { params: { max: -1 } },
				clean_up: 'set_attribute_groups'
			},
			{
				action: 'fetch_attributes',
				payload: { params: { max: -1 } },
				bindTo: { prop: 'formData.attribute_group_id', param: 'attributeGroupId' },
				clean_up: 'set_attributes'
			},
			{
				action: 'fetch_attribute_options',
				payload: { params: { max: -1 } },
				bindTo: { prop: 'formData.attribute_id', param: 'attributeId' },
				clean_up: 'set_attribute_options'
			}
		]
	},

	methods: {
		...mapActions({
			// fetch_categories: 'categories/fetch_categories',
			// set_categories: 'categories/set_categories',
			fetch_attribute_groups: 'attribute_groups/fetch_attribute_groups',
			set_attribute_groups: 'attribute_groups/set_attribute_groups',
			fetch_attributes: 'attributes/fetch_attributes',
			set_attributes: 'attributes/set_attributes',
			fetch_attribute_options: 'attributes/fetch_attribute_options',
			set_attribute_options: 'attributes/set_attribute_options'
		}),

		setupForm(itemData, formData) {
			this.optionsList = this.setupFormSubItemsList(
				itemData.filter_options,
				'opt_i'
			);
			return updateFormData(itemData, formData);
		}
	}
};
</script>
